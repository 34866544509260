<template>
  <header class="header-global">
    <base-nav class="navbar-main" type="" effect="light" expand>
      <nuxt-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img style="bottom:45px" class="myuse-image" src="~assets/images/logo.svg" alt="logo">
      </nuxt-link>

      <div class="row" slot="content-header" slot-scope="{closeMenu}">
        <div class="col-6 collapse-brand">
          <nuxt-link to="/">
            <img src="~assets/images/brand/myuse_caps.png">
          </nuxt-link>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center" slot-scope="{closeMenu}">
        <li
          v-for="(item, index) in content.links"
          :key="'link-' + index"
          :class="item.link.replace('/', '')"
          class="nav-item"
        >
          <nuxt-link
            :to="item.link"
            :title="item.alt"
            @click.native="closeMenu"
            class="nav-link nav-link-icon">
            <span class="nav-link-inner--text mx-2">
              {{ item.title }}
            </span>
          </nuxt-link>
          </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "~/components/argon/BaseNav";
import CloseButton from "~/components/argon/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    content () {
      return this.$store.state.nav.data
    }
  }
};
</script>
<style scoped>
.myuse-image{
  color:purple;
 height:30px
}
</style>