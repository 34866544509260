<template>
  <footer class="footer py-2">
    <div class="container">
      <!-- <div class="row row-grid align-items-left">
        <div class="col-md-6">
          <h3 class="text-primary font-weight-light mb-2">{{ content.social }}</h3>
        </div>
        <div class="col-md-6 text-md-right btn-wrapper">
          <a target="_blank" rel="noopener" href="https://twitter.com/myuse"
             class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg">
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <a target="_blank" rel="noopener" href="https://www.facebook.com/myuse"
             class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg">
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <a target="_blank" rel="noopener" href="https://instagram.com/myuse"
             class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a target="_blank" rel="noopener" href="https://snapchat.com/myuse"
             class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg">
            <font-awesome-icon :icon="['fab', 'snapchat']" />
          </a>
        </div>
      </div> -->
      <!-- <hr> -->
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-3">
          <div class="copyright">
            &copy; {{ year }} {{ content.brand }}
          </div>
        </div>
        <!-- <div class="col-md-9" style="display: contents; padding: 0 5px">
          <ul class="nav nav-footer justify-content-end" >
            <li class="nav-item"  v-for="(item, index) in content.links" :key="'link-' + index">
              <nuxt-link :to="item.link" class="nav-link">{{ item.title }}</nuxt-link>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faFacebook, faInstagram, faSnapchat } from '@fortawesome/free-brands-svg-icons'
library.add(faTwitter, faFacebook, faInstagram, faSnapchat)

export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    content () {
      return this.$store.state.nav.data
    }
  }
};
</script>
