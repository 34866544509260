import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b764da = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _8b022be4 = () => interopDefault(import('../pages/achievements.vue' /* webpackChunkName: "pages/achievements" */))
const _5355aed7 = () => interopDefault(import('../pages/activities.vue' /* webpackChunkName: "pages/activities" */))
const _32ba372c = () => interopDefault(import('../pages/assessment.vue' /* webpackChunkName: "pages/assessment" */))
const _ef4748c8 = () => interopDefault(import('../pages/drugs/index.vue' /* webpackChunkName: "pages/drugs/index" */))
const _71f86922 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5f70148e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _50e7e30d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _d1081556 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _a44d066e = () => interopDefault(import('../pages/drugs/_drug.vue' /* webpackChunkName: "pages/drugs/_drug" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _44b764da,
    name: "about"
  }, {
    path: "/achievements",
    component: _8b022be4,
    name: "achievements"
  }, {
    path: "/activities",
    component: _5355aed7,
    name: "activities"
  }, {
    path: "/assessment",
    component: _32ba372c,
    name: "assessment"
  }, {
    path: "/drugs",
    component: _ef4748c8,
    name: "drugs"
  }, {
    path: "/login",
    component: _71f86922,
    name: "login"
  }, {
    path: "/privacy",
    component: _5f70148e,
    name: "privacy"
  }, {
    path: "/register",
    component: _50e7e30d,
    name: "register"
  }, {
    path: "/support",
    component: _d1081556,
    name: "support"
  }, {
    path: "/drugs/:drug",
    component: _a44d066e,
    name: "drugs-drug"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
